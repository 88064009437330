<template>
  <div>
    <div class="tab_content">
      <div class="bg_c">
        <div class="img_bg">
          <div class="img_title">
            <h3>家生活 更精彩</h3>
            <h6>致力于中国家庭客群的消费需求升级与品质体验</h6>
          </div>
        </div>
      </div>
      <div class="fix_content">
        <div class="right-float">
          <div class="right-float-card" :class="{ active: imgIndex === 2 }">
            <div>
              <img src="@/assets/img/call_us_icon3.png" alt="" />
              <span>400-890-0567</span>
            </div>
            <div>
              <img src="@/assets/img/call_us_icon2.png" alt="" />
              <span>入驻咨询</span>
            </div>
            <div>
              <img src="@/assets/img/call_us_icon1.png" alt="" />
              <span>加盟咨询</span>
            </div>
          </div>
          <div class="right-float-button">
            <div
              class="right_button"
              @mouseover="imgIndex === 0 ? (imgIndex = 1) : ''"
              @mouseleave="imgIndex === 1 ? (imgIndex = 0) : ''"
              @click="handleClick"
            >
              <img
                v-if="imgIndex === 0"
                src="@/assets/img/call_us_right_icon1.png"
                alt=""
              />
              <img
                v-if="imgIndex === 1"
                src="@/assets/img/call_us_right_icon2.png"
                alt=""
              />
              <img
                v-if="imgIndex === 2"
                src="@/assets/img/call_us_right_icon3.png"
                alt=""
              />
            </div>
            <!-- <div>
              <img src="@/assets/img/call_us_right_icon2.png" alt="" />
            </div>
            <div>
              <img src="@/assets/img/call_us_right_icon3.png" alt="" />
            </div> -->
          </div>
        </div>
        <div class="gome_introduce">
          <div class="left_card">
            <h1 class="left_card-entitle">INTRODUCTION</h1>
            <h3 class="left_card-title">国美家介绍</h3>
            <div class="bottom_line"></div>
          </div>
          <div class="right_card">
            <p class="right_card-con" style="margin-bottom: 0">
              <span class="right-card-title">国美家</span>是国美集团旗下聚焦“新零售、新服务、全客群”,为家庭用户提供全新的生活消费体验的平台公司。国美家围绕“家 · 生活”战略打造线上/线下融合发展的本地生活数字化平台——乐活秀，聚焦项目周边1-8KM家庭客群，线上依托于乐活秀APP/小程序，到网、到家、到店商户全量入驻，线下依托国美家门店及周边生活服务点，通过商品展示体验、家延伸服务、家娱乐社交三大功能板块，为家庭用户提供全新的生活消费体验。
            </p>
            <p class="right_card-btn" @click="enter_introduce">了解更多</p>
          </div>
          <!-- 联系我们悬浮弹窗 -->
        </div>
      </div>

      <div class="carousel">
        <img v-if="false" class="carousel_img" src="@/assets/img/bg_img.png" alt="" />
      </div>
      <div class="stores">
        <div class="stores-content">
          <h1 class="stores-content-entitle">STANDARD</h1>
          <h3 class="stores-content-title">国美家店型标准</h3>
          <div class="bottom_line"></div>
          <div class="stores-content-con">
            <div class="stores_con_1">
              <div class="header-row">
                <img
                  class="stores_con_1-image"
                  src="@/assets/img/stores_image_1.png"
                  alt=""
                />城市级
              </div>
              <div class="description-label"><span>10</span><span class="unit">万㎡以上</span></div>
            </div>
            <div class="stores_con_1">
              <div class="header-row">
                <img
                  class="stores_con_1-image"
                  src="@/assets/img/stores_image_2.png"
                  alt=""
                />商圈级
              </div>
              <div class="description-label">
                <span>5</span><span class="little_symbol">-</span
                ><span>10</span><span class="unit">万㎡</span>
              </div>
            </div>
            <div class="stores_con_1">
              <div class="header-row">
                <img
                  class="stores_con_1-image"
                  src="@/assets/img/stores_image_3.png"
                  alt=""
                />区域级
              </div>
              <div class="description-label">
                <span>3</span><span class="little_symbol">-</span
                ><span>5</span><span class="unit">万㎡</span>
              </div>
            </div>
            <div class="stores_con_1">
              <div class="header-row">
                <img
                  class="stores_con_1-image"
                  src="@/assets/img/stores_image_4.png"
                  alt=""
                />社区级
              </div>
              <div class="description-label">
                <span>1</span><span class="little_symbol">-</span
                ><span>3</span><span class="unit">万㎡</span>
              </div>
            </div>
            <div class="stores_con_1">
              <div class="header-row">
                <img
                  class="stores_con_1-image"
                  src="@/assets/img/stores_image_5.png"
                  alt=""
                />驿站级
              </div>
              <div class="description-label">
                <span>400</span><span class="little_symbol">-</span
                ><span>2000</span><span class="unit">㎡</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gome_project">
        <!-- 下面这个div撑开了高度 -->
        <div class="gome_project-content">
          <div class="gome_project-content-header">
            <h1 class="gome_project-content-header-entitle">
              COMMERCIAL PROJECT
            </h1>
            <h3 class="gome_project-content-header-title">
              国美家自持商业项目
            </h3>
          </div>
          <div class="bottom_line"></div>
          <!-- 轮播图部分，没有轮播 -->
          <div class="gome_project-content-body">
            <!-- 左箭头按钮 -->
            <div class="prev carousel-button">
              <el-button
                type="info"
                icon="el-icon-arrow-left icon-font"
                circle
                @click="arrowClick('prev')"
              ></el-button>
            </div>
            <!-- 右箭头按钮 -->
            <div class="next carousel-button">
              <el-button
                type="info"
                icon="el-icon-arrow-right icon-font"
                circle
                @click="arrowClick('next')"
              ></el-button>
            </div>
            <!-- 轮播图 -->
            <el-carousel
              height="600px"
              arrow="never"
              indicator-position="outside"
              ref="cardShow"
              :autoplay="false"
            >
              <el-carousel-item v-for="(item, index) in list" :key="index">
                <!-- <div class="gome_project-content-body"> -->
                <div class="gome_project-content-body-leftcard">
                  <img class="gome_project-image" :src="item.url" alt="" />
                </div>
                <div class="gome_project-content-body-rightcard">
                  <h3>{{ item.title }}</h3>
                  <div class="bottom_line_title"></div>
                  <p class="gome_project-content-body-rightcard-title">
                    {{ item.desc }}
                  </p>
                  <div class="gome_project-content-body-rightcard-bottom">
                    <div
                      class="
                        gome_project-content-body-rightcard-bottom-location
                      "
                    >
                      <h5>项目位置</h5>
                      <p
                        class="gome_project-content-body-rightcard-bottom-value"
                      >
                        {{ item.city }}
                      </p>
                    </div>
                    <div
                      class="
                        gome_project-content-body-rightcard-bottom-vertical
                      "
                    ></div>
                    <div
                      v-if="index !== 2"
                      class="gome_project-content-body-rightcard-bottom-mass"
                    >
                      <h5>项目体量</h5>
                      <p
                        class="gome_project-content-body-rightcard-bottom-value"
                      >
                        {{ item.num }}<span>万㎡</span>
                      </p>
                    </div>
                    <div
                      v-if="index !== 2"
                      class="
                        gome_project-content-body-rightcard-bottom-vertical
                      "
                    ></div>
                    <div
                      class="gome_project-content-body-rightcard-bottom-park"
                    >
                      <h5>商业体量</h5>
                      <p
                        class="gome_project-content-body-rightcard-bottom-value"
                      >
                        {{ item.bus }}<span>万㎡</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </el-carousel-item>
            </el-carousel>
          </div>
          <div></div>
          <!-- 轮播图结束 -->
          <div class="gome_project-more" @click="enter_project">查看更多</div>
        </div>
      </div>

      <div class="gome_business">
        <div class="gome_business-content">
          <div class="gome_business-content-header">
            <h1 class="gome_business-content-header-entitle">BUSINESS MODE</h1>
            <h3 class="gome_business-content-header-title">国美家商业模式</h3>
          </div>
          <div class="bottom_line"></div>
          <div class="gome_business-content-body">
            <div class="gome_business-content-body-leftcard">
              <h3>商品展示体验+家延伸服务+家娱乐社交</h3>
              <div class="bottom_line_title"></div>
              <p>
                商品展示体验——以展厅模式为核心，迭代传统零售的经营模式，从单一销售功能转变为展示、体验等功能。同时引入魔镜、视频导购等功能，提升消费者体验感，建立品牌与用户情感的纽带，培养用户忠诚度。
              </p>
              <p>
                家延伸服务——以“家”场景为切入点进行业态规划，将家庭功能移至乐活秀项目内，如客厅-电影院/KTV、餐厅-共享餐饮、书房-儿童/成人教培。实现在乐活秀项目内居家生活场景的延伸，重塑家庭生活方式。
              </p>
              <p>
                家娱乐社交——多功能娱乐场景，让每个家人满足社交需求，各得其所。
              </p>
            </div>
            <div class="gome_business-content-body-rightcard">
              <img
                class="gome_business-image"
                src="@/assets/img/img22.png"
                alt=""
              />
            </div>
          </div>
          <div class="gome_business-more" @click="enter_business">查看更多</div>
        </div>
      </div>
      <div class="gome_news">
        <div class="gome_news-title">
          <h1 class="gome_news-entitle">NEWS</h1>
          <h3 class="gome_news-title">国美家新闻</h3>
        </div>
        <div class="bottom_line"></div>
        <div class="gome_news-content">
          <div
            class="gome_news-content-con"
            v-for="item in items"
            :key="item.ids"
            @click="$router.push(`/newsDetail${item.idx}`)"
          >
            <img :src="item.image" alt="" />
            <div class="gome_news-content-con-title">
              <h3 class="gome_news-content-con-title-name">
                {{ item.name }}
              </h3>
              <p class="gome_news-content-con-title-des">
                {{ item.des }}
              </p>
              <h2 class="gome_news-content-con-title-time">{{ item.date }}</h2>
              <h2 class="gome_news-content-con-title-read-all">
                阅读全部 >
              </h2>
            </div>
          </div>
        </div>
        <div class="gome_news-more" v-if="items.length > 6" @click="enter_news">
          查看更多
        </div>
      </div>

      <!-- <div class="gome_partner">
            <div class="gome_partner-content">
            <h3 class="gome_partner-content-title">国美家合作伙伴</h3>
            <div class="bottom_line"></div>
            <el-carousel trigger="click" height="150px">
              <el-carousel-item v-for="item in 4" :key="item">
                <div class="gome_partner-content-con">
                  <div class="stores_con_1">
                    <img src="@/assets/img/logo_.png" alt="">
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->

      <!-- <div class="gome_join">
        <div class="gome_join-title">
          <h3 class="gome_join-title-left">是否想要入驻国美家?</h3>
          <div>
            <span>加入 我们</span>
            <img src="@/assets/img/right_arrow.png" alt="" />
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      projectList: [
        {
          id: "001",
          img: require("@/assets/img/project_1.jpg"),
        },
        {
          id: "002",
          img: require("@/assets/img/project_2.jpg"),
        },
        {
          id: "003",
          img: require("@/assets/img/project_3.jpg"),
        },
      ],
      list: [
        {
          title: "乐活秀北京国美商都",
          desc:
            "乐活秀北京国美商都运用全新的理念诠释了次时代家庭生活方式，营造了家庭生活的“新维度”，通过打造全新的共享生活场景，主力当代都市人群塑造更健康、更趣味、更充实、更多元的全新生活方式。利用共享模式将个人生活、家庭生活、家庭娱乐、展示体验等多元业态进行融合，实现了线上到线下的统一。",
          city: "北京",
          num: "56",
          bus: "32",
          url: require("@/assets/img/banner/beijing.png"),
        },
        {
          title: "乐活秀广州智慧城",
          desc: "共享餐饮，共享教培，共享零售，美食美客四大板块。",
          city: "广州",
          num: "24",
          bus: "4.6",
          url: require("@/assets/img/banner/guangzhou.png"),
        },
        {
          title: "乐活秀长沙湘江玖号",
          desc:
            "泛国美线下体验中心、共享生活空间、情景生活、体验空间、家庭聚乐空间、商务休闲延伸五大板块。",
          city: "长沙",
          num: "\\",
          bus: "16.2",
          url: require("@/assets/img/banner/changsha.png"),
        },
        {
          title: "乐活秀西安悦秀城",
          desc:
            "以共享体验为核心驱动力，带动相关业态发展，以零售展示、儿童业态、特色餐饮、娱乐休闲四大板块相互融合辅助构成其核心竞争力，分为美丽新市集、休闲益智园、活力欢聚场、活力娱乐城、美味鉴赏家五大业态功能组团。",
          city: "西安",
          num: "24",
          bus: "2.2",
          url: require("@/assets/img/banner/xian.png"),
        },
        {
          title: "乐活秀成都万和广场",
          desc: "打造创新、共享、多元的特色社区型商业。",
          city: "成都",
          num: "5.8",
          bus: "2.4",
          url: require("@/assets/img/banner/chengdu.png"),
        },
      ],
      //国家自持项目轮播图设置
      // selfOwnedProjectList: [
      //   {
      //     describe: {
      //       title: "北京国美商都",
      //       content:
      //         "国美商都运用全新的理念诠释了次时代家庭生活方式，营造了家庭生活的“新维度”，通过打造全新的共享生活场景，主力当代都市人群塑造更健康、更趣味、更充实、更多元的全新生活方式。利用共享模式将个人生活、家庭生活、家庭娱乐、展示体验等多元业态进行融合，实现了线上到线下的统一。",
      //     },
      //     place: {
      //       title: "项目位置",
      //       content: "北京",
      //     },
      //     volume: {
      //       title: "项目体量",
      //       content: "17.3万",
      //     },
      //     parking: {
      //       title: "商业体量",
      //       content: "4.6万",
      //     },
      //   },
      // ],
      items: [
        {
          idx: "1",
          image: require("@/assets/img/news_img1.png"),
          name: "让蓉城人民再“安逸”...",
          des:
            "近年来，向来以“安逸”著称的成都，正以前所未有的速度快速发展，在国内经济发展浪潮中...",
          date: "2021.08.23",
        },
        {
          idx: "2",
          image: require("@/assets/img/news_img3.png"),
          name: "重庆沙坪坝区副区长...",
          des:
            "2021年10月20日  重庆沙坪坝区副区长代建红、国际物流枢纽园区董事长罗书权一行拜会...",
          date: "2021.10.20",
        },
      ],
      imgSrc: require("@/assets/img/call_us_right_icon1.png"),
      imgIndex: 0,
      flag: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    enter_project() {
      this.$router.push("/index/project");
    },
    enter_introduce() {
      this.$router.push("/index/gome");
    },
    enter_business() {
      this.$router.push("/index/business");
    },
    enter_news() {
      this.$router.push("/index/news");
    },
    handleClick() {
      this.flag = !this.flag;
      if (!this.flag) {
        this.imgIndex = 0;
      } else {
        this.imgIndex = 2;
      }
    },
    //轮播图左右按钮切换轮播的方法
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss">
.el-button--info {
  font-size: 16px !important;
  i {
    font-weight: 700 !important;
    font-size: 10px;
  }
}
</style>
<style lang="scss" scoped>
.tab_content {
  width: 100%;
  overflow-x: auto;
  .bg_c {
    background: url("~@/assets/img/bg_img.png") no-repeat center 0;
    min-width: 1200px;
    .img_bg {
      width: 1200px;
      height: 1000px;
      margin: auto;
      display: flex;
      align-items: center;
      .img_title {
        width: 756px;
        height: 155px;
        margin: auto;
      }
      .img_title h3 {
        height: 98px;
        font-size: 70px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 15.56px;
      }
      .img_title h6 {
        width: 756px;
        height: 37px;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 10px;
        margin-top: 20px;
      }
    }
  }
  .fix_content {
    width: 100%;
    position: relative;

    // 联系我们悬浮框
    .right-float {
      position: absolute;
      z-index: 11;
      top: 245px;
      right: 120px;
      &-card {
        width: 220px;
        height: 186px;
        background: #ffffff;
        box-shadow: 0px 10px 10px 0px #e9edf1;
        opacity: 0;
        transition: all 0.5s ease-in-out;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        div {
          margin-left: 30px;
          vertical-align: middle;
          span {
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 24px;
            padding-left: 2px;
            float: left;
            /*
            &:hover {
              cursor: pointer;
            }
            */
          }
          img {
            display: inline-block;
            margin-right: 10px;
            float: left;
            /*
            &:hover {
              cursor: pointer;
            }
            */
          }
        }
        //突出小箭头
        &:after {
          box-sizing: content-box;
          width: 0;
          height: 0;
          position: absolute;
          right: -16px;
          bottom: 20px;
          padding: 0;
          border-bottom: 10px solid transparent;
          border-top: 10px solid transparent;
          border-left: 10px solid #fff;
          border-right: 10px solid transparent;
          display: block;
          content: "";
          z-index: 10;
        }

        &.active {
          opacity: 1;
        }
      }
      .right_button {
        position: absolute;
        top: 109px;
        left: 246px;
        width: 50px;
        height: 50px;
        margin-top: 23px;

        border-radius: 25px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
      &-button {
      }
    }

    .gome_introduce {
      width: 1200px;
      margin: auto;
      position: relative;
      .left_card {
        padding: 60px;
        height: 123px;
        opacity: 1;
        position: relative;

        &-entitle {
          font-size: 78px;
          font-family: PingFang SC;
          text-align: center;
          font-weight: 700;
          line-height: 94px;
          color: rgba(31, 35, 41, 0.1);
        }
        &-title {
          margin-top: -29px;
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
          color: #1f2329;
        }
        .bottom_line {
          width: 60px;
          height: 8px;
          background: linear-gradient(to right, #f6ba4f, #ea8427);
          border-radius: 4px;
          opacity: 1;
          text-align: bottom;
          top: 10px;
          margin: auto;
          position: relative;
        }
      }
      .right_card {
        padding: 50px 66px 40px 69px;
        box-sizing: border-box;
        border-radius: 10px;
        height: 330px;
        background-color: white;
        box-shadow: 0px 10px 59px rgba(0, 0, 0, 0.15);

        p > span {
          display: inline-block;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          line-height: 38px;
          box-align: baseline;
          margin-right: 15px;
          color: #1f2329;
        }
        &-con {
          display: inline-block;
          float: none;
          //text-indent: 1em;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 40px;
          box-align: baseline;
          color: #1f2329;
          opacity: 1;
        }

        &-btn {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 26px;
          color: #eb8729;
          opacity: 1;
          margin-top: 34px;
          cursor: pointer;
        }
      }
    }
  }

  .carousel {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 101px;
    .carousel_img {
      height: 600px;
      width: 1200px;
      border-radius: 20px;
      margin: auto;
    }
  }
  .stores {
    width: 100%;
    height: 600px;
    // background: rgba(37, 87, 255, 0.04);
    // opacity: 1;
    background: linear-gradient(to right, #f4a937, #eb8729);
    &-content {
      width: 1200px;
      margin: auto;
      padding-top: 50px;
      &-entitle {
        font-size: 78px;
        font-family: PingFang SC;
        text-align: center;
        font-weight: 700;
        line-height: 94px;
        color: rgba(31, 35, 41, 0.1);
      }
      &-title {
        margin-top: -29px;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 600;
        text-align: center;
        line-height: 40px;
        color: #1f2329;
        opacity: 1;
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #ffffffff, #ffffff33);
        opacity: 1;
        top: 10px;
        position: relative;
        margin: auto;
        border-radius: 4px;
      }
    }
  }

  .stores-content-con {
    display: flex;
    margin-top: 104px;
    justify-content: space-between;
    color: white;
    .header-row {
      display: flex;
      font-size: 24px;
      .stores_con_1-image {
        margin-right: 8px;
        width: 32px;
      }
    }
    .description-label {
      margin-top: 46px;
      font-size: 60px;
    }
    .unit {
      font-size: 18px;
    }
  }

  .gome_project {
    width: 100%;
    // background: rgba(98, 37, 54, 0.04);
    // opacity: 1;
    background: #ffffff;
    // height: 1020px;
    &:hover {
      .carousel-button {
        display: block;
      }
    }
    &-content {
      width: 1200px;
      // height: 1030px;
      margin: auto;
      // padding-top: 50px;
      position: relative;

      &-header {
        // height: 123px;
        margin-top: 50px;
        h1 {
          font-size: 78px;
          font-family: PingFang SC;
          text-align: center;
          font-weight: 700;
          line-height: 94px;
          color: rgba(31, 35, 41, 0.1);
        }
        h3 {
          margin-top: -29px;
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
          color: #1f2329;
          opacity: 1;
        }
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f4a937, #eb8729);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        margin: auto;
        position: relative;
        top: 10px;
      }
      &-body {
        margin-top: 68px;
        height: 600px;
        background: #ffffff;
        box-shadow: 0px 10px 59px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        // 为轮播图按钮添加样式
        .carousel-button {
          width: 64px;
          height: 64px;
          position: absolute;
          display: none;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 10;
          color: #000000;
          .el-button {
            width: 100%;
            height: 100%;
            font-size: 10px;
            background-color: #e8e8e8;
            color: #000000 !important;
            border: 0px !important;
          }
        }
        .prev {
          left: -93px;
        }
        .next {
          right: -93px;
        }
        &-rightcard {
          float: right;
          width: 600px;
          opacity: 1;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          h3 {
            margin: 50px 50px 10px 50px;
            font-size: 30px;
            font-family: Hiragino Sans GB;
            font-weight: 600;
            line-height: 30px;
            color: #1f2329;
            opacity: 1;
          }
          .bottom_line_title {
            margin-left: 50px;
            width: 40px;
            height: 8px;
            background: linear-gradient(to right, #f4a937, #eb8729);
            border-radius: 4px;
            opacity: 1;
            text-align: bottom;
            position: relative;
          }
          &-title {
            margin: 22px 50px 50px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 40px;
            color: #666666;
            opacity: 1;
          }
          &-bottom {
            margin: 50px;
            // background-color: #f4a937;
            display: flex;
            justify-content: space-around;
            &-vertical {
              // float: left;
              display: inline-block;
              width: 1px;
              height: 91px;
              background: #cccccc;
              opacity: 1;
            }
            &-location {
              // float: left;
              // margin: 0px 42px 0px 0px;
            }
            &-mass {
              // float: left;
              // padding: 0px 32px 0px;
            }
            &-park {
              // float: left;
              // padding: 0px 0px 0px 42px;
            }

            h5 {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 26px;
              color: #333333;
              opacity: 1;
            }
            .gome_project-content-body-rightcard-bottom-value {
              font-size: 45px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 58px;
              color: #333333;
              opacity: 1;
              margin-top: 14px;
              span {
                font-size: 25px;
                margin-left: 5px;
              }
            }
          }
        }
        &-leftcard {
          float: left;
          width: 600px;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          overflow: hidden;
          .gome_project-image {
            height: 600px;
          }
        }
        /deep/.el-carousel__indicator--horizontal {
          padding: 40px 0;
          button {
            width: 40px;
            background: #d8d8d8;
            opacity: 1;
          }
          &.is-active {
            button {
              background-color: #333333;
            }
          }
        }
      }
      .gome_project-more {
        //margin: auto;
        margin: 80px auto 0 auto;
        margin-bottom: 100px;
        width: 280px;
        // top: 82px;
        // position: relative;
        border-radius: 4px;
        border: 1px solid #ea8528;
        text-align: center;
        font-size: 16px;
        font-family: PMicrosoft YaHei;
        font-weight: 400;
        line-height: 48px;
        color: #ea8528;
        cursor: pointer;
      }
    }
  }

  .gome_business {
    width: 100%;
    // background: rgba(98, 37, 54, 0.04);
    // opacity: 1;
    height: 1070px;
    background: #f9f7f8;
    &-content {
      width: 1200px;
      height: 1030px;
      margin: auto;
      padding-top: 50px;
      &-header {
        //height: 123px;
        h1 {
          font-size: 78px;
          font-family: PingFang SC;
          text-align: center;
          font-weight: 700;
          line-height: 94px;
          color: rgba(31, 35, 41, 0.1);
        }
        h3 {
          margin-top: -29px;
          font-size: 40px;
          font-family: PingFang SC;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
          color: #1f2329;
          opacity: 1;
          margin-bottom: 10px;
        }
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f4a937, #eb8729);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        margin: auto;
        position: relative;
      }
      &-body {
        overflow: hidden;
        margin-top: 67px;
        height: 600px;
        background: #ffffff;
        box-shadow: 0px 10px 59px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        &-leftcard {
          float: left;
          width: 600px;
          opacity: 1;
          h3 {
            margin: 50px 40px 10px 40px;
            font-size: 28px;
            font-family: Hiragino Sans GB;
            font-weight: 600;
            line-height: 30px;
            color: #1f2329;
            opacity: 1;
          }
          .bottom_line_title {
            margin-left: 50px;
            width: 40px;
            height: 8px;
            background: linear-gradient(to right, #f4a937, #eb8729);
            border-radius: 4px;
            opacity: 1;
            text-align: bottom;
            position: relative;
          }
          p {
            margin: 22px 40px 30px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 1.8;
            color: #666666;
            opacity: 1;
          }
        }
        &-rightcard {
          float: right;
          width: 600px;
          .gome_business-image {
            height: 600px;
          }
        }
      }
      .gome_business-more {
        margin: auto;
        width: 280px;
        top: 82px;
        position: relative;
        border-radius: 4px;
        border: 1px solid #ea8528;
        text-align: center;
        font-size: 16px;
        font-family: PMicrosoft YaHei;
        font-weight: 400;
        line-height: 48px;
        color: #ea8528;
        cursor: pointer;
      }
    }
  }
  .gome_news {
    min-height: 776px;
    background: linear-gradient(to right, #f4a937, #eb8729);
    margin: auto;
    padding-top: 50px;
    &-entitle {
      font-size: 78px;
      font-family: PingFang SC;
      text-align: center;
      font-weight: 700;
      line-height: 94px;
      color: rgba(31, 35, 41, 0.1);
    }
    &-title {
      margin-top: -29px;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      color: #1f2329;
      opacity: 1;
    }
    .bottom_line {
      width: 60px;
      height: 8px;
      background: linear-gradient(to right, #ffffffff, #ffffff33);
      border-radius: 4px;
      opacity: 1;
      text-align: bottom;
      top: 10px;
      margin: auto;
      position: relative;
    }
    &-content {
      display: flex;
      width: 1230px;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      flex-wrap: wrap;
      padding-top: 80px;
      &-con {
        background: url("~@/assets/img/stores_bg2.png") no-repeat center 0;
        width: 380px;
        height: 512px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        margin-right: 30px;
        border-radius: 10px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.3);
        &:hover {
          cursor: pointer;
          box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.5);
          top: -20px;
        }
        img {
          width: 320px;
          height: 167px;
          margin-top: 60px;
        }
      }
      .gome_news-content-con-title {
        display: block;
        width: 100%;
        padding: 0 20px;
        position: absolute;
        top: 257px;
        box-sizing: border-box;
        &-name {
          width: 100%;
          opacity: 1;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #1f2329;
          white-space: nowrap; /*把文本强制显示在一行*/
          text-overflow: ellipsis; /*超出部分显示...*/
        }
        &-des {
          box-sizing: border-box;
          height: 74px;
          opacity: 1;
          font-size: 18px;
          font-family: Microsoft YaHei; //国美家新闻正文字体
          font-weight: 400;
          line-height: 29px;
          color: #666666;
        }
        &-time {
          opacity: 1;
          font-size: 14px;
          font-family: Microsoft YaHei;
          line-height: 19px;
          color: #666666;
          margin-top: 16px;
        }
        // 添加阅读全部
        &-read-all {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #eb8729;
          line-height: 24px;
          margin-top: 26px;
          cursor: pointer;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .gome_news-more {
      margin: auto;
      width: 280px;
      bottom: -100px;
      position: relative;
      border-radius: 4px;
      border: 1px solid #ffffff;
      text-align: center;
      font-size: 16px;
      font-family: PMicrosoft YaHei;
      font-weight: 400;
      line-height: 48px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /*.gome_partner {
    width: 100%;
    background: #f7f9ff;
    opacity: 1;
    &-content {
      width: 1200px;
      margin: auto;
      padding: 0 0;
      &-title {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 300;
        line-height: 45px;
        color: #404040;
        opacity: 1;
      }
      .bottom_line {
        width: 117px;
        height: 5px;
        background: rgba(122, 80, 255, 0.53);
        opacity: 1;
        margin-top: 10px;
      }
      .gome_partner-content-con {
        display: flex;
        justify-content: center;
        // align-items: flex-start;
        padding: 50px 0 80px;
        box-sizing: border-box;
        .stores_con_1 {
          img {
            width: 60%;
            height: auto;
            object-fit: contain;
          }
          display: flex;
          justify-content: center;
          // flex-direction: column;
          // align-items: center;
          div {
            // margin-top: 20px;
            font-size: 23px;
            font-family: PingFang SC;
            font-weight: 300;
            line-height: 38px;
            color: #3d3d3d;
            opacity: 1;
          }
        }
      }
      .el-carousel__button {
        width: 10px;
        height: 10px;
        background: rgba(124, 124, 124, 0.57);
        border-radius: 50%;
        opacity: 1;
      }
      .el-carousel__indicator.is-active button {
        width: 10px;
        height: 10px;
        background: #7a50ff;
        border-radius: 50%;
        opacity: 1;
      }
    }
  }
  .gome_join {
    width: 1200px;
    margin: auto;
    padding: 60px 0;
    &-title {
      height: 50px;
      &-left {
        float: left;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 300;
        line-height: 33px;
        color: #404040;
        opacity: 1;
      }
      div {
        float: right;
        margin-right: 50px;
        span {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 300;
          line-height: 28px;
          color: #7a50ff;
          opacity: 1;
          vertical-align: middle;
        }
        img {
          display: inline-block;
          vertical-align: middle;
          margin-left: 30px;
          width: 24px;
          height: auto;
        }
      }
    }
  }*/
}
</style>
